import { useContext } from "react";
import AnalyticsServiceContext from "../context/AnalyticsServiceContext";
import {
  AnalyticsEvent,
  SupportedProviders,
} from "../services/analytics/analytics.dto";

export const useAnalyticsClick = (
  event: AnalyticsEvent,
  callback: (data?: any) => void,
  provider: SupportedProviders = "All"
) => {
  const analyticsService = useContext(AnalyticsServiceContext);

  if (!analyticsService) {
    console.error("Analytics Service is not initialised");
  }

  return (data?: any) => {
    analyticsService.sendEvent(event, provider);
    callback(data);
  };
};
