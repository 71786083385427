export type AnalyticsEvent = {
  name: EventType;
  options?: Record<string, string | number | boolean>;
};

export type SupportedProviders = "Firebase" | "Amplitude" | "All";

export enum EventType {
  CLICK_HOME = "home click",
  CLICK_LOGIN = "login click",
  PAGE_WAITLIST = "waitlist page open",
  PAGE_SHARE_CHAT = "share chat page open",
  PAGE_PROFILE = "profile page open",
  PAGE_API_KEYS = "api keys page open",
  PAGE_HOME = "home page open",
  PAGE_THREAD_CHAT = "thread chat page open",
  PAGE_CHAT_AGENT = "chat agent page open",
  CLICK_GOOGLE_LOGIN = "google login clicked",
  CLICK_INSTALL_AGENTS = "install agent clicked",
  CLICK_DEFAULT_BUTTON = "Default Button Clicked",
  SEND_MESSAGE_TO_AGENT = "message to agent is sent",
  TOGGLE_MEMORY = "long term memory is toggled",
  PAGE_APPVEDA = "page appveda is opened",
}
