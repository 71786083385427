import Head from "next/head";

interface ISeoProps {
  title?: string;
  description?: string;
  image?: string;
  author?: string;
}

const Seo: React.FC<ISeoProps> = (props: ISeoProps) => {
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      ></meta>
      <>
        <title>
          {props.title ?? "AiSidekik: Your Personal AI Assistant Marketplace"}
        </title>
        <meta
          property="og:title"
          content={
            props.title ?? "AiSidekik: Your Personal AI Assistant Marketplace"
          }
        />
        <meta
          name="twitter:title"
          content={
            props.title ?? "AiSidekik: Your Personal AI Assistant Marketplace"
          }
        />
      </>
      <>
        <meta
          name="description"
          content={
            props.description ??
            "Get the perfect AI-driven assistants for all your needs with AiSidekik. Customize, collaborate, and elevate your projects with ease."
          }
        />
        <meta
          property="og:description"
          content={
            props.description ??
            "Get the perfect AI-driven assistants for all your needs with AiSidekik. Customize, collaborate, and elevate your projects with ease."
          }
        />
        <meta
          name="twitter:description"
          content={
            props.description ??
            "Get the perfect AI-driven assistants for all your needs with AiSidekik. Customize, collaborate, and elevate your projects with ease."
          }
        />
      </>
      <>
        <meta
          property="og:image"
          content={props.image ?? "https://i.imgur.com/QCehQS9.jpg"}
        />
        <meta
          name="twitter:image"
          content={props.image ?? "https://i.imgur.com/QCehQS9.jpg"}
        />
        <meta
          name="image"
          content={props.image ?? "https://i.imgur.com/QCehQS9.jpg"}
        />
      </>
      {
        <>
          <meta name="author" content={props.image ?? "Divyanshu Negi"} />
          <meta name="HandheldFriendly" content="True" />
          <meta name="MobileOptimized" content="320" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://aisidekik.com/" />
        </>
      }
    </Head>
  );
};

export default Seo;
