import { useRouter } from "next/router";
import { useEffect } from "react";
import Seo from "../components/Seo";
import GoogleLogin from "../components/social-login-buttons/GoogleLogin";
import { isAllowedUser } from "../utils/utils";
import LoadingDots from "../components/animatedThreeDots";
import { useSession } from "../context/SessionContext";

const LoginPage = () => {
  const { user, loading } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (user) {
      if (isAllowedUser(user?.email)) {
        router.push("/home");
      } else {
        router.push("/waitlist");
      }
    }
  }, [router, user]);

  return (
    <>
      <Seo />
      <main className="w-screen h-screen flex flex-col bg-gradient-to-t from-primary to-secondary">
        {loading ? (
          <div className="m-auto">
            <LoadingDots />
          </div>
        ) : (
          <>
            <div className="w-full sm:w-[90%] h-screen m-auto flex">
              <LoginBlock />
            </div>
            <p className="text-white text-small m-auto mb-10 opacity-40 w-[90%] sm:w-[700px] text-center">
              This is a beta version of AISidekik and only invited users can
              access the dashboard. If you want to be a part of the beta testing
              program, please contact us at {"div@aisidekik.com"}
            </p>
          </>
        )}
      </main>
    </>
  );
};

const AppTemplate = () => {
  return <div className="w-96 h-96 bg-slate-600 rounded-xl m-auto"></div>;
};

const LoginBlock = () => {
  return (
    <div className="flex m-auto w-full">
      <div className="flex m-auto flex-col w-[300px]">
        <img
          src="./images/icon-blue.png"
          alt="logo"
          className="w-20 m-auto mb-6"
        />
        <h1 className="text-white text-xl opacity-90 font-normal m-auto mb-3">
          Log in to AISidekik
        </h1>
        <GoogleLogin />
      </div>
    </div>
  );
};

export default LoginPage;
