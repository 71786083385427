import { GoogleLogo } from "phosphor-react";
import { useSession } from "../../context/SessionContext";
import { useAnalyticsClick } from "../../hooks/useAnalyticsClick";
import { EventType } from "../../services/analytics/analytics.dto";

const GoogleLogin = () => {
  const { signinWithGoogle } = useSession();

  const loginWithGoogle = useAnalyticsClick(
    {
      name: EventType.CLICK_GOOGLE_LOGIN,
      options: {
        method: "Google",
      },
    },
    () => {
      signinWithGoogle();
    }
  );

  return (
    <div
      onClick={() => loginWithGoogle()}
      className="mt-6 cursor-pointer flex items-center justify-center w-full px-4 py-3 text-md font-normal text-white bg-tertiary border border-transparent rounded shadow-sm hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-600"
    >
      <GoogleLogo size={24} weight="bold" />
      <p className="ml-4">Continue with Google</p>
    </div>
  );
};
export default GoogleLogin;
