import React from "react";

function LoadingDots() {
  return (
    <div className="flex justify-center">
      <div className="h-2 w-2 rounded-full mx-1 bg-gray-500 animate-bounce animation-delay-100"></div>
      <div className="h-2 w-2 rounded-full mx-1 bg-gray-500 animate-bounce animation-delay-300"></div>
      <div className="h-2 w-2 rounded-full mx-1 bg-gray-500 animate-bounce animation-delay-500"></div>
    </div>
  );
}

export default LoadingDots;
